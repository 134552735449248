<template>
    <div>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <td></td>
                    <td class="text-center">Tansacción</td>
                    <td class="text-center">Nº</td>
                    <td class="text-center">Procesado</td>
                    <td class="text-center">Fecha Trans.</td>
                    <td class="text-center">Creado</td>
                    <td class="text-center">D/C</td>
                    <td class="text-center">Monto</td>
                    <td class="text-center">Cuenta</td>
                    <td class="text-center">Código</td>
                    <td class="text-center">Nombre</td>
                </thead>
                <tbody v-if="asientos.length">
                    <tr
                        v-for="(asiento, x) in asientos"
                        :key=x
                    >
                        <td><a target="_blank" :href="`/asientoContableVista/${asiento.acctg_trans_id}`" ><v-icon color="green">mdi-eye</v-icon></a></td>
                        <td class="text-center">{{asiento.acctg_trans_id}}</td>
                        <td class="text-center">{{asiento.acctg_trans_entry_seq_id}}</td>
                        <td class="text-center">{{asiento.is_posted}}</td>
                        <td class="text-center">{{asiento.transaction_date}}</td>
                        <td class="text-center">{{asiento.posted_date}}</td>
                        <td class="text-center">{{asiento.debit_credit_flag}}</td>
                        <td class="text-center">{{asiento.amount}}</td>
                        <td class="text-center">{{asiento.description}}</td>
                        <td class="text-center">{{asiento.account_code}}</td>
                        <td class="text-center">{{asiento.account_name}}</td>
                    </tr> 
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="11" class="text-center">
                            Sin asientos contables registrados
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
export default {
    name:'AccountingEntries',
    props:{
        asientos:{
            type:Array,
            default:() => {return []}
        }
    },
    created(){
        console.log(this.asientos)
    }
   
}
</script>