<template>
  <div>
    <v-overlay :value="overlay" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-data-table
        :headers="headers"
        :options.sync="options"
        :items="lista"
        :items-per-page="itemsPerPage"
        :loading="loadingTable"
        hide-default-footer
        :key="`list-purchase-invoices-${tenantId}`"
        class="elevation-1"
      >
        <template v-slot:top>
          <ExpandableFilters
            classButton="py-0 py-md-2 text-center mb-md-0 col-6 col-sm-2 px-md-0"
            iconButtom="mdi-upload"
            classTitle="col-0"
            :filters="filters"
            @datafilters="cargarLista(true)"
          />
          <v-col>
            <v-btn
              color="primary"
              small
              class="ma-2 white--text"
              @click="exportar()"
            >
              <v-icon left>mdi-microsoft-excel</v-icon>
              Exportar
            </v-btn>
            <v-btn
              color="primary"
              small
              class="ma-2 white--text"
              @click="newPurchaseInvoice()"
            >
              <v-icon left>mdi-plus</v-icon>
              Nueva factura
            </v-btn>
          </v-col>
        </template>
        <template v-slot:item.opcion="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="red"
                v-bind="attrs"
                v-on="on"
                @click="editFormInvoice(item)"
                v-if="
                  item.invoice_type_id == 'EXPENDING_INVOICE' ||
                    item.invoice_type_id == 'PURCHASE_NOTA_DEBITO'
                "
              >
                <v-icon
                  :color="
                    item.status_id == 'INVOICE_IN_PROCESS' ? 'warning' : 'info'
                  "
                >
                  {{
                    item.status_id == "INVOICE_IN_PROCESS"
                      ? "mdi-pencil"
                      : "mdi-eye"
                  }}
                </v-icon>
              </v-btn>
              <v-btn
                icon
                color="red"
                v-bind="attrs"
                v-on="on"
                @click="editFormInvoice(item)"
                v-else
              >
                <v-icon color="info">mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>
              {{ item.status_id == 'INVOICE_IN_PROCESS' ? (item.invoice_type_id == 'EXPENDING_INVOICE' ||
                item.invoice_type_id == 'PURCHASE_NOTA_DEBITO' ? 'Editar factura' : 'Ver factura') : 'Ver
                            factura'}}
            </span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="
              (item.invoice_type_id == 'PURCHASE_INVOICE' ||
                item.invoice_type_id == 'PURC_RTN_INVOICE' ||
                item.invoice_type_id == 'EXPENDING_INVOICE' ||
                item.invoice_type_id == 'PURCHASE_NOTA_DEBITO') &&
                item.status_id == 'INVOICE_IN_PROCESS'
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="formInvoice(item)"
              >
                <v-icon>mdi-priority-high</v-icon>
              </v-btn>
            </template>
            <span>Actualizar cabecera</span>
          </v-tooltip>
        </template>
        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          >
          </v-pagination>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark small color="primary">
          <v-btn
            icon
            dark
            @click="
              dialog = false;
              editInvoce = false;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ cabecera.tipo_factura }}#
            {{
              (cabecera.codigo_establecimiento != null
                ? cabecera.codigo_establecimiento + "-"
                : "") +
                (cabecera.codigo_punto_emision
                  ? cabecera.codigo_punto_emision + "-"
                  : "") +
                cabecera.invoice_number
            }}
            / {{ cabecera.invoice_id }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="
                dialog = false;
                editInvoce = false;
              "
            >
              Salir
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card class="pl-6 pr-6 pt-6">
          <v-form ref="form_update_invoice">
            <v-row>
              <v-col md="6" cols="12" class="pt-2 pb-0">
                <v-alert
                  dense
                  text
                  icon="mdi-file-chart"
                  light
                  color="blue-grey darken-2"
                >
                  Datos de la factura
                </v-alert>
                <v-row>
                  <v-col sm="12" md="9" class="pt-0 pb-0">
                    <v-text-field
                      label="Proveedor*"
                      :rules="required"
                      type="text"
                      small
                      readonly
                      v-model="cabecera.razon_social"
                      append-icon="mdi mdi-magnify"
                      @click:append="documentosProveedor"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="6" md="3" class="pt-0 pb-0">
                    <v-text-field
                      label="Estado*"
                      :rules="required"
                      type="text"
                      small
                      readonly
                      v-model="cabecera.estado"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="12" md="2" class="pt-0 pb-0">
                    <v-text-field
                      label="Establecimiento*"
                      v-model="cabecera.estab"
                      :rules="required"
                      small
                      :readonly="!editInvoice"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="12" md="2" class="pt-0 pb-0">
                    <v-text-field
                      label="Pto. Emisión*"
                      :rules="required"
                      v-model="cabecera.pto_emi"
                      small
                      :readonly="!editInvoice"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="12" md="2" class="pt-0 pb-0">
                    <v-text-field
                      label="Número*"
                      small
                      :rules="required"
                      v-model="cabecera.secuencial"
                      :readonly="!editInvoice"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="6" md="3" class="pt-0 pb-0">
                    <v-text-field
                      label="Fecha de emisión*"
                      :rules="required"
                      type="date"
                      small
                      :readonly="!editInvoice"
                      v-model="cabecera.inv_fecha"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="6" md="3" class="pt-0 pb-0">
                    <v-text-field
                      label="Fecha de vencimiento "
                      :rules="required"
                      type="date"
                      small
                      :readonly="!editInvoice"
                      v-model="cabecera.due_date"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="6" md="6" class="pt-0 pb-0">
                    <v-text-field
                      v-if="
                        cabecera.invoice_type_id == 'PURC_RTN_INVOICE' &&
                          (cabecera.status_id == 'INVOICE_READY' ||
                            cabecera.status_id == 'INVOICE_PAID')
                      "
                      label="Documento origen"
                      :rules="required"
                      type="text"
                      small
                      :readonly="!editInvoice"
                      v-model="
                        cabecera.invto_tipo + ' : ' + cabecera.invto_numero
                      "
                    ></v-text-field>

                    <v-autocomplete
                      v-if="
                        cabecera.invoice_type_id == 'PURC_RTN_INVOICE' &&
                          cabecera.status_id == 'INVOICE_IN_PROCESS'
                      "
                      v-model="cabecera.invoice_id_from"
                      label="Documento origen"
                      :items="invoicesProv"
                      item-text="invoice_number"
                      item-value="invoice_id"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col sm="12" class="pt-0 pb-0">
                    <v-text-field
                      label="Clave de acceso*"
                      small
                      :rules="required"
                      :readonly="!editInvoice"
                      v-model="cabecera.clave_acceso"
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          @click="saveHeadersInvoice"
                          icon
                          v-if="editInvoice && totalSuccess"
                          ><v-icon>mdi-content-save-all</v-icon></v-btn
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="6" cols="12" class="pt-2">
                <v-alert
                  dense
                  text
                  icon="mdi-cash-multiple"
                  light
                  color="blue-grey darken-2"
                >
                  Pagos de la factura
                  <div style="float:right">
                    <b>Saldo: ${{ saldo }}</b>
                  </div>
                </v-alert>

                <v-data-table
                  :headers="headPagos"
                  :options.sync="options"
                  :items="pagos"
                  :items-per-page="100"
                  hide-default-footer
                  class="elevation-1"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col cols="12">
              <v-alert
                dense
                text
                icon="mdi-format-list-checks"
                light
                color="blue-grey darken-2"
              >
                Items de la facutra
              </v-alert>
              <v-data-table
                :headers="headItems"
                :options.sync="options"
                :items="itemsFactura"
                :items-per-page="100"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:footer>
                  <v-row class="mt-3">
                    <v-col cols="12" md="9">
                      <v-expansion-panels accordion v-model="panel">
                        <v-expansion-panel class="px-0 pb-0">
                          <v-expansion-panel-header color="blue-grey lighten-5">
                            ASIENTOS CONTABLES
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <AccountingEntries :asientos="cabecera.asientos" />
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-alert
                        dense
                        text
                        icon="mdi-cash-100"
                        light
                        color="blue-grey darken-2"
                      >
                        Totales
                      </v-alert>
                      <v-simple-table>
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td>Sub total</td>
                              <td>${{ totalesFactura.subTotal }}</td>
                            </tr>
                            <tr>
                              <td>Sub total IVA 12%</td>
                              <td>${{ totalesFactura.subTotalConIva }}</td>
                            </tr>
                            <tr>
                              <td>Sub total IVA 0%</td>
                              <td>${{ totalesFactura.subTotalSinIva }}</td>
                            </tr>
                            <tr>
                              <td>IVA</td>
                              <td>${{ totalesFactura.totalIVA }}</td>
                            </tr>
                            <tr>
                              <td>Total</td>
                              <td>
                                <b>${{ totalesFactura.total }}</b>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogInvoiceSupplier" max-width="900px" height="400px">
      <v-card>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Número
                </th>
                <th class="text-left">
                  Fecha
                </th>
                <th class="text-left">
                  Proveedor
                </th>
                <th class="text-left">
                  Valor
                </th>
                <th class="text-left">
                  Estado
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="invoice in invoiceSupplier"
                :key="invoice.clave_acceso"
                @click="selectInvoice(invoice)"
                style="cursor:pointer"
              >
                <td>
                  <v-icon>mdi-hand-pointing-right</v-icon>
                  {{
                    invoice.estab +
                      "-" +
                      invoice.pto_emi +
                      "-" +
                      invoice.secuencial
                  }}
                </td>
                <td>{{ invoice.fecha }}</td>
                <td>{{ invoice.razon_social }}</td>
                <td>{{ invoice.importe_total }}</td>
                <td>{{ invoice.estado }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogFormInvoice"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark small color="primary">
          <v-btn icon dark @click="closeFromInvoice">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span v-if="invoiceName == null">Factura</span>
            <span v-else>{{ invoiceName }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn small dark text @click="anularDocumento()">
              <v-icon>mdi-cancel</v-icon>
              Anular documento
            </v-btn>

            <v-btn
              v-if="tipoDocumento == 'NOTA_DEBITO_INT_CMP'"
              text
              small
              @click="descargarDetalleCuentas()"
            >
              <v-icon>mdi-file-excel</v-icon>
              Detalle de cuentas
            </v-btn>

            <v-btn dark small text @click="descargarPdf(claveAcceso)">
              <v-icon>mdi-file-pdf</v-icon>
              Doc. electrónico
            </v-btn>

            <v-btn dark text @click="closeFromInvoice">
              Salir
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="mt-16" v-if="invoiceId == null || invoiceType == null">
          <v-card elevation="2" class="col-md-4 offset-md-4">
            <v-alert
              color="primary"
              border="left"
              elevation="4"
              colored-border
              icon="mdi-file-document"
            >
              Crear nueva factura de compra
            </v-alert>
            <v-form ref="form_invoce">
              <v-col>
                <v-autocomplete
                  v-model="invoiceType"
                  label="Seleccione el tipo de factura"
                  :items="invoiceTypes"
                  :rules="required"
                  item-value="invoice_type_id"
                  item-text="description"
                  hide-details
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  v-model="supplier"
                  label="Seleccione el Proveedor"
                  :items="suppliers"
                  :rules="required"
                  item-value="party_id"
                  item-text="name"
                  outlined
                  hide-details
                  dense
                ></v-autocomplete>
              </v-col>
            </v-form>
            <v-col>
              <v-col class="text-center">
                <v-btn
                  color="primary"
                  class="mx-1"
                  @click="createHeaderPurchaseInvocie"
                >
                  <v-icon>mdi-check-all</v-icon> CREAR FACTURA
                </v-btn>
                <v-btn
                  color="blue-grey lighten-4"
                  class="mx-1 mt-3 mt-sm-0"
                  @click="closeFromInvoice"
                >
                  <v-icon>mdi-cancel</v-icon> Cancelar
                </v-btn>
              </v-col>
            </v-col>
          </v-card>
        </div>
        <div v-else>
          <InvoiceForm
            :invoiceId="invoiceId"
            :suppliers="suppliers"
            class="pl-6 pr-6 pt-6"
            @invoiceName="newInvoiceName"
            @claveAcceso="newClaveAcceso"
            @storeInvoice="storeInvoice"
            @tipoDocumento="newTipoDocumento"
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import ExpandableFilters from "../general/ExpandableFilters";
import AccountingEntries from "../general/AccountingEntries";
import InvoiceForm from "../general/InvoiceForm";
import Vue from "vue";

export default {
  name: "FacturasCompraComponent",
  components: {
    ExpandableFilters,
    AccountingEntries,
    InvoiceForm,
  },
  data: () => ({
    headers: [
      { text: "ID", value: "invoice_id" },
      { text: "Numero", value: "numero_factura" },
      { text: "Fecha", value: "invoice_date" },
      { text: "Fecha vencimiento", value: "due_date" },
      { text: "Crédito", value: "credit" },
      { text: "Tipo", value: "tipo_factura" },
      { text: "Estado", value: "estado" },
      { text: "Proveedor", value: "proveedor" },
      { text: "Total", value: "total", align: "end" },
      { text: "Opciones", value: "opcion", width: "120px" },
    ],
    itemsEstado: [
      { estadoId: "INVOICE_READY", estadoNombre: "Listo" },
      { estadoId: "INVOICE_PAID", estadoNombre: "Pagado" },
      { estadoId: "INVOICE_IN_PROCESS", estadoNombre: "En proceso" },
    ],
    headItems: [
      { text: "Opciones", value: "opcion", align: "center", width: 50 },
      { text: "No.", value: "secId", align: "start" },
      { text: "Descripcion", value: "description", align: "start" },
      { text: "Cantidad", value: "cantidad", align: "end" },
      { text: "Precio", value: "valorUnitario", align: "end" },
      { text: "%IVA", value: "pct_iva", align: "end" },
      { text: "IVA", value: "valor_iva", align: "end" },
      { text: "Total", value: "total", align: "end" },
    ],
    headPagos: [
      { text: "ID", value: "payment_id", align: "center", width: 150 },
      { text: "Tipo", value: "payment_type", align: "center", width: 150 },
      { text: "Referencia", value: "payment_ref_num", align: "start" },
      { text: "Fecha", value: "effective_date", align: "start" },
      { text: "Monto", value: "amount_applied", align: "start" },
    ],
    cabecera: {
      invoice_date: null,
      razon_social: null,
      estado: null,
      pto_emi: null,
      estab: null,
      secuencial: null,
      clave_acceso: null,
      tipo_documento: null,
      asientos: [],
    },
    filters: [
      {
        cols: 6,
        class: "py-0 py-md-1 col-md-1 pl-0 pl-sm-1",
        v_model: "",
        label: "ID factura",
        type: "input_text",
      },
      {
        cols: 6,
        class: "py-0 py-md-2 col-md-2 pl-0 pl-sm-2",
        v_model: "",
        label: "Número",
        type: "input_text",
      },
      {
        cols: 6,
        class: "py-0 py-md-2 col-md-2 pl-0 pl-sm-2",
        v_model: "",
        label: "Proveedor",
        type: "input_text",
      },
      {
        cols: 6,
        class: "py-0 py-md-2 col-md-3 pr-0 pr-sm-2 mb-1",
        v_model: [],
        label: "Fecha",
        type: "input_date_time",
        clearable: true,
      },
      {
        cols: 6,
        class: "py-0 py-md-2 col-md-2 pl-0 pl-sm-2",
        v_model: "",
        label: "Tipo",
        type: "input_select",
        text: "tipoNombre",
        value: "tipoId",
        clearable: true,
        multiple: true,
        items: [
          { tipoId: "PURCHASE_INVOICE", tipoNombre: "Factura compra" },
          { tipoId: "EXPENDING_INVOICE", tipoNombre: "Factura gasto" },
          { tipoId: "NCC_INT_CMP", tipoNombre: "Nota crédito interna" },
          { tipoId: "PURC_RTN_INVOICE", tipoNombre: "Nota de crédito" },
          { tipoId: "NOTA_DEBITO_INT_CMP", tipoNombre: "Nota débito interna" },
          //{'tipoId':'PURCHASE_NOTA_DEBITO','tipoNombre': 'Nota débito compras'},
          { tipoId: "ND_COMPRA", tipoNombre: "Nota de débito" },
          { tipoId: "LIQ_COMPRA", tipoNombre: "Liquidación de compra" },
          { tipoId: "PURC_NOTA_VENTA", tipoNombre: "Nota de venta" },
        ],
      },
      {
        cols: 6,
        class: "py-0 py-md-2 col-md-2 pl-0 pl-sm-2",
        v_model: "",
        label: "Estado",
        type: "input_select",
        text: "estadoNombre",
        value: "estadoId",
        clearable: true,
        items: [
          { estadoId: "INVOICE_READY", estadoNombre: "Listo" },
          { estadoId: "INVOICE_PAID", estadoNombre: "Pagado" },
          { estadoId: "INVOICE_IN_PROCESS", estadoNombre: "En proceso" },
        ],
      },
    ],
    lista: [],
    options: {},
    dialog: false,
    panel: "",
    dialogInvoiceSupplier: false,
    editInvoice: false,
    itemsFactura: [],
    totalesFactura: [],
    invoiceSupplier: [],
    pagos: [],
    invoiceTypes: [],
    suppliers: [],
    total_pagado: "",
    currentPage: 1,
    pageCount: 1,
    itemsPerPage: 20,
    searchNumber: "",
    searchFechaInv: "",
    searchEstado: "",
    searchProveedor: "",
    term: 0,
    totalSuccess: false,
    overlay: false,
    dialogFormInvoice: false,
    invoiceId: null,
    invoiceType: null,
    supplier: null,
    invoiceName: null,
    claveAcceso: null,
    tipoDocumento: null,
    invoicesProv: [],
    required: [(v) => !!v || "El campo es requerido"],
  }),
  watch: {
    options: {
      handler() {
        this.cargarLista(false);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("master", [
      "loadingTable",
      "user",
      "tenantId",
      "paramAlertQuestion",
    ]),

    saldo() {
      let pagado = 0;
      this.pagos.forEach((obj) => {
        pagado += parseFloat(obj.amount_applied.replace(",", ""));
      });

      if (typeof this.totalesFactura.total != "undefined")
        this.totalesFactura.total = this.totalesFactura.total.replace(",", "");

      return parseFloat(this.totalesFactura.total - pagado).toFixed(2);
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
      "setLoadingBtn",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification", "loadingBtn"]),

    cargarLista(filtrar) {
      if (filtrar == true) this.currentPage = 1;

      this.setLoadingTable(true);
      this.setUrl("invoice");
      this.lista = [];
      this.requestApi({
        method: "GET",
        data: {
          tipo: "PURCHASE_INVOICE",
          page: this.currentPage,
          page_count: this.pageCount,
          page_size: this.itemsPerPage,
          invoiceId: this.filters[0].v_model,
          proveedor: this.filters[2].v_model,
          invoiceNumber: this.filters[1].v_model,
          statusId: this.filters[5].v_model,
          invoiceDate: this.filters[3].v_model,
          invoiceType: this.filters[4].v_model,
        },
      }).then((res) => {
        this.lista = res.data._embedded.invoice[0].invoice;
        this.pageCount = res.data._embedded.invoice[0].page_count;
        this.suppliers = res.data._embedded.invoice[1];
        this.invoiceTypes = res.data._embedded.invoice[2];
        this.setLoadingTable(false);
      });
    },

    tipoIdentProveedor(provId) {
      let tipoIdentId = "";
      for (let i = 0; i < this.suppliers.length; i++) {
        if (this.suppliers[i].party_id == provId) {
          tipoIdentId = this.suppliers[i].party_identification_type_id;
        }
      }
      return tipoIdentId;
    },

    formInvoice(item) {
      this.overlay = true;
      this.setUrl(`invoice/${item.invoice_id}`);
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          const {
            cabecera,
            itemsFactura,
            totales,
            pagos,
            total_pagado,
            asientos,
          } = res.data;

          this.term = item.days_term;
          this.cabecera = cabecera;
          this.cabecera.razon_social = cabecera.participante;
          this.itemsFactura = itemsFactura.map((obj) => {
            obj.cantidad = obj.cantidad.split(",").join("");
            return obj;
          });
          this.totalesFactura = totales;
          this.pagos = pagos;
          this.total_pagado = total_pagado;
          this.idValueSupplier = item.id_value;
          this.dialog = true;
          this.cabecera.clave_acceso = cabecera.autorizacion_sri;
          this.cabecera.due_date = this.termDay(
            cabecera.invoice_date,
            item.days_term
          );
          this.cabecera.secuencial = cabecera.invoice_number;
          this.cabecera.pto_emi = cabecera.codigo_punto_emision;
          this.cabecera.estab = cabecera.codigo_establecimiento;
          this.cabecera.due_date = cabecera.due_date;
          this.cabecera.invoice_type_id = cabecera.invoice_type_id;
          this.cabecera.tipo_documento = cabecera.tipo_documento;
          this.cabecera.asientos = asientos.sort((a, b) => {
            return a.acctg_trans_entry_seq_id - b.acctg_trans_entry_seq_id;
          });
          this.cabecera.sub_total_imp1 = totales.subTotalSinIva.replace(
            ",",
            ""
          );
          this.cabecera.sub_total_imp2 = totales.subTotalConIva.replace(
            ",",
            ""
          );
          this.editInvoice = item.status_id == "INVOICE_IN_PROCESS";
          this.invoicesProv = res.data.doc_proveedor;
        })
        .catch(() => {})
        .then(() => {
          this.overlay = false;
        });
    },

    documentosProveedor() {
      let codigo_documento = "";
      this.overlay = true;
      console.log(this.cabecera.documento);
      switch (this.cabecera.documento) {
        case "PURCHASE_INVOICE":
          codigo_documento = "01";
          break;
        case "EXPENDING_INVOICE":
          codigo_documento = "01";
          break;
        case "PURC_RTN_INVOICE":
          codigo_documento = "04";
          break;
        case "LIQ_COMPRA":
          codigo_documento = "03";
          break;
        case "NOTA_DEBITO":
          codigo_documento = "05";
          break;
        case "PURCHASE_NOTA_DEBITO":
          codigo_documento = "05";
          break;
      }

      if (codigo_documento == "") {
        Vue.swal({
          html: "No se encontró el tipo de documento",
          showConfirmButton: false,
          ...this.paramAlertQuestion,
        });
        this.overlay = false;
        return false;
      }

      this.overlay = true;
      this.setUrl("comprobante-electronico");
      this.requestApi({
        method: "GET",
        data: {
          page_size: 2000,
          tipo: codigo_documento,
          estado: "RECIBIDO",
          identificacion: this.idValueSupplier,
        },
      })
        .then((res) => {
          console.log(res);
          this.dialogInvoiceSupplier = true;
          this.invoiceSupplier = res.data._embedded.comprobantes;
        })
        .catch(() => {})
        .then(() => {
          this.overlay = false;
        });
    },

    selectInvoice(invoice) {
      let total1 = parseFloat(this.totalesFactura.total.split(",").join(""));
      let total2 = parseFloat(invoice.importe_total.split(",").join(""));
      this.totalSuccess =
        parseFloat(total1 - 0.01).toFixed(2) == total2 ||
        parseFloat(total1 - 0.02).toFixed(2) == total2 ||
        parseFloat(total2 - 0.01).toFixed(2) == total1 ||
        parseFloat(total2 - 0.02).toFixed(2) == total1 ||
        total1 + 0.01 == total2 ||
        total1 + 0.02 == total2 ||
        total2 + 0.01 == total1 ||
        total2 + 0.02 == total1 ||
        total1 == total2;

      if(invoice.titulo_gratuito)
        this.totalSuccess =true

      if (!invoice.titulo_gratuito && !this.totalSuccess) {
        Vue.swal({
          html:
            "El total del documento seleccionado no coincide con el documento a actualizar",
          showConfirmButton: false,
          ...this.paramAlertQuestion,
        });
        return false;
      }

      invoice.due_date = this.termDay(invoice.fecha, this.term);
      invoice.inv_fecha = invoice.fecha;
      Object.assign(this.cabecera, invoice);
      this.dialogInvoiceSupplier = false;
    },

    saveHeadersInvoice() {
      if (!this.$refs.form_update_invoice.validate()) return false;

      this.overlay = true;
      Vue.swal({
        html: `Está seguro de actualizar el documento con los datos seleccionados?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("invoice");
          this.requestApi({
            method: "POST",
            data: {
              ...this.cabecera,
              typeAction: "updateHeaderPurchaseInvoice",
            },
          })
            .then((res) => {
              this.alertNotification({ param: { html: res.data.msg } });
              this.cargarLista(true);
              this.dialog = false;
              this.overlay = false;
            })
            .then(() => {})
            .catch(() => {
              this.overlay = false;
            });
        }
      });
    },

    termDay(date, term) {
      let dueDateInvoice = new Date(date + "T00:00:00");
      dueDateInvoice.setDate(dueDateInvoice.getDate() + parseInt(term));
      let day = dueDateInvoice.getDate();
      let month = dueDateInvoice.getMonth() + 1;
      let year = dueDateInvoice.getFullYear();

      if (month < 10) month = "0" + month;

      if (day < 10) day = "0" + day;

      return year + "-" + month + "-" + day;
    },

    handlePageChange(value) {
      //this.currentPage = value
      this.cargarLista(false);
    },

    exportar() {
      console.log(this.filters);
      this.overlay = true;
      this.setUrl("invoice");
      this.requestApi({
        method: "PUT",
        data: {
          tipo: "PURCHASE_INVOICE",
          invoiceId: this.filters[0].v_model,
          proveedor: this.filters[2].v_model,
          invoiceNumber: this.filters[1].v_model,
          statusId: this.filters[5].v_model,
          invoiceDate: this.filters[3].v_model,
          invoiceType: this.filters[4].v_model,
          page_size: 100000,
          page: 1,
        },
      })
        .then((res) => {
          let link = document.createElement("a");
          link.href = res.data._embedded.invoice[0];
          link.download = "Documentos.xlsx";
          link.click();
        })
        .catch(() => {})
        .then(() => {
          this.overlay = false;
        });
    },

    newPurchaseInvoice() {
      this.dialogFormInvoice = true;
    },

    createHeaderPurchaseInvocie() {
      if (!this.$refs.form_invoce.validate()) return false;
      if (this.invoiceType == "LIQ_COMPRA") {
        if (!(this.tipoIdentProveedor(this.supplier) == "CEDULA")) {
          alert("El tipo de identificación del proveedor debe ser CÉDULA");
          //alert(this.tipoIdentProveedor(this.supplier));
          //alert(this.invoiceType);
          return false;
        }
      }

      Vue.swal({
        html: `Está seguro de crear la factura?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("invoice");
          this.requestApi({
            method: "POST",
            data: {
              invoiceType: this.invoiceType,
              supplier: this.supplier,
              typeAction: "createInvoicePurchase",
            },
          })
            .then((res) => {
              console.log(res);
              this.invoiceId = res.data.res.invocieId;
              this.alertNotification({ param: { html: res.data.res.msg } });
              this.cargarLista(true);
            })
            .then(() => {})
            .catch(() => {
              this.overlay = false;
            });
        }
      });
    },

    closeFromInvoice() {
      this.dialogFormInvoice = false;
      this.invoiceId = null;
      this.invoiceType = null;
      this.supplier = null;
    },

    editFormInvoice(item) {
      this.invoiceType = item.invoice_type_id;
      this.invoiceId = item.invoice_id;
      this.dialogFormInvoice = true;
    },

    newInvoiceName(name) {
      this.invoiceName = name;
    },

    newClaveAcceso(ca) {
      this.claveAcceso = ca;
    },

    newTipoDocumento(td) {
      this.tipoDocumento = td;
    },

    storeInvoice() {
      this.cargarLista();
      this.closeFromInvoice();
    },

    descargarPdf(clave_acceso) {
      //console.log(this.tipoDocumento)

      if (this.tipoDocumento == "LIQ_COMPRA") {
        window.frames["framePrint"].location.replace(
          "https://app.sufactura.ec/descargarcomp?ca=" + clave_acceso
        );
      } else {
        this.overlay = true;
        this.setUrl("comprobante-electronico");

        this.requestApi({
          method: "PATCH",
          data: {
            clave_acceso: clave_acceso,
          },
        })
          .then((res) => {
            //console.log(res)
            var a = document.createElement("a");
            a.href =
              "data:application/pdf;base64," +
              res.data.detail[2]
                .trim()
                .replace('"', "")
                .replace(/(\r\n|\n|\r)/gm, "");
            a.download = "factura" + clave_acceso.substring(24, 39) + ".pdf";
            a.click();
          })
          .catch(() => {})
          .then(() => {
            this.overlay = false;
          });
      }
    },

    anularDocumento() {},

    descargarDetalleCuentas() {
      this.setUrl("invoice");
      this.requestApi({
        method: "POST",
        data: {
          invoice_id: this.invoiceId,
          typeAction: "descargarDocumentosServicio",
        },
      })
        .then((res) => {
          console.log(res.data.res);
          let link = document.createElement("a");
          link.href = res.data.res.invoice[0];
          link.download = "DocumentosServicio.xlsx";
          link.click();
        })
        .catch(() => {})
        .then(() => {
          this.overlay = false;
        });
    },
  },

  mounted() {
    this.setTitleToolbar("FACTURAS DE COMPRAS");
    if (this.$route.params.invoiceId != null) {
      this.invoiceId = this.$route.params.invoiceId;
      this.invoiceType = "PURCHASE_INVOICE";
      this.dialogFormInvoice = true;
    }
  },
};
</script>
<style>
.v-expansion-panel-header {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
</style>
