<template>
    <div>
        <v-row>
            <v-col md="6" cols="12" class="pt-2 pb-0">
                <v-alert dense text icon="mdi-file-chart" light color="blue-grey darken-2">
                    Datos de la facutra
                </v-alert>
                <v-row>
                    <v-col sm="12" md="9" class="pt-0 pb-0">
                        <v-autocomplete v-model=supplier label="Seleccione el Proveedor" :items=suppliers :rules=required
                            item-value="party_id" item-text="name"></v-autocomplete>
                    </v-col>
                    <v-col sm="6" md="3" class="pt-0 pb-0">
                        <v-text-field v-model="invoice.estado" label="Estado*" :rules=required type="text"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="2" class="pt-0 pb-0">
                        <v-text-field v-model="invoice.codigo_establecimiento" v-mask="'###'"
                            label="Establecimiento*"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="2" class="pt-0 pb-0">
                        <v-text-field v-model="invoice.codigo_punto_emision" label="Pto. Emisión*"
                            v-mask="'###'"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="2" class="pt-0 pb-0">
                        <v-text-field v-model="invoice.invoice_number" label="Número*"></v-text-field>
                    </v-col>
                    <v-col sm="6" md="3" class="pt-0 pb-0">
                        <v-text-field v-model="invoice.inv_fecha" label="Fecha de emisión*" :rules=required
                            type="date"></v-text-field>
                    </v-col>
                    <v-col sm="6" md="3" class="pt-0 pb-0">
                        <v-text-field v-model="invoice.due_date" label="Fecha de vencimiento " :rules=required
                            type="date"></v-text-field>
                    </v-col>
                    <v-col sm="6" md="6" class="pt-0 pb-0">
                        <v-autocomplete v-if="invoice.invoice_type_id == 'PURC_RTN_INVOICE'"
                            v-model="invoice.invoice_id_from" label="Documento origen" :items="invoicesProv"
                            item-text="invoice_number" item-value="invoice_id" >
                        </v-autocomplete>
                        <!-- <v-text-field v-if="invoice.invoice_type_id == 'PURC_RTN_INVOICE'" label="Documento origen"
                            :rules=required type="text" small :readonly=!editInvoice
                            v-model="invoice.invto_tipo + ' : ' + invoice.invto_numero"></v-text-field> -->
                    </v-col>
                </v-row>
                <v-row>
                    <v-col sm="3">
                        <v-checkbox v-model="electroinicInvoice"
                            :label="`${electroinicInvoice ? 'Factura electrónica' : 'Factura física'}`"
                            single-line></v-checkbox>
                    </v-col>
                    <v-col sm="9">
                        <v-text-field v-model="invoice.autorizacion_sri" label="Clave de acceso*" small type="number"
                            :rules=required counter="49" v-mask="'#################################################'">
                            <template v-slot:append v-if="update">
                                <v-btn icon @click="storeHeaderInvoice" :loading=store>
                                    <v-icon>mdi-content-save</v-icon>
                                </v-btn>

                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col md="6" cols=12 class="pt-2">
                <v-alert dense text icon="mdi-cash-multiple" light color="blue-grey darken-2">
                    Pagos de la factura

                    <v-menu offset-y v-if="invoice.status_id == 'INVOICE_READY'">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mx-1 white--text" rounded color="blue" dark v-bind="attrs" v-on="on" small fab>
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="(item, index) in menuPagos" :key="index" @click="ingPago(index)">
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <div style="float:right"><b>Saldo ${{ saldo }}</b></div>
                </v-alert>

                <v-checkbox v-model="pagoTarjeta"
                    :label="`${pagoTarjeta ? 'Pago con tarjeta de crédito' : 'Pago con tarjeta de crédito'}`"
                    single-line></v-checkbox>

                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    ID
                                </th>
                                <th class="text-left">
                                    Tipo
                                </th>
                                <th class="text-left">
                                    Referencia
                                </th>
                                <th class="text-left">
                                    Fecha
                                </th>
                                <th class="text-left">
                                    Monto
                                </th>
                                <th class="text-left">
                                    Acción
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="pagos.length > 0">
                            <tr v-for="(pago, p) in pagos" :key=p>
                                <td>

                                    <v-btn v-if="pago.payment_type_id == 'VENDOR_PAYMENT'" text target="_blank"
                                        :to="`/ver-pago-emitido/${pago.payment_id}`">
                                        <v-icon color="info">mdi-eye</v-icon>

                                    </v-btn>

                                    <v-btn v-if="pago.payment_type_id == 'RETENCION_EMITIDA'" text target="_blank"
                                        :to="`/retencion-emitida/${pago.payment_id}`">
                                        <v-icon color="info">mdi-eye</v-icon>

                                    </v-btn>

                                    <v-btn v-if="pago.payment_type_id == 'CRUCE_NCC'" text
                                        title="Haga click para abrir el documento" target="_blank"
                                        :to="`/ver-factura-compra/${pago.fa_rel.invoice_id}`">
                                        <v-icon color="info">mdi-eye</v-icon>

                                    </v-btn>

                                </td>
                                <td>{{ pago.payment_type }}</td>
                                <td>
                                    {{ pago.payment_ref_num }}
                                    <span v-if="pago.payment_type_id == 'CRUCE_NCC'">
                                        {{ pago.fa_rel.codigo_establecimiento }}-{{ pago.fa_rel.codigo_punto_emision }}-{{
                                            pago.fa_rel.invoice_number }}
                                    </span>
                                </td>
                                <td>{{ pago.effective_date.substring(0, 10) }}</td>
                                <td>${{ pago.amount_applied }}</td>
                                <td>
                                    <v-btn @click="downloadPdfRetention(pago)" icon small color="error"
                                        v-if="pago.payment_type_id == 'RETENCION_EMITIDA'">
                                        <v-icon>mdi-file-pdf</v-icon>
                                    </v-btn>


                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="5" class="text-center">
                                    <v-icon>mdi-alert</v-icon> Sin pagos realizados
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols=12>
                <v-alert dense text light color="blue-grey darken-2">
                    <div style="float:left">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" color="primary" class="mx-1" fab x-small
                                    @click=addInvoiceItem>
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Agregar item</span>
                        </v-tooltip>
                    </div>
                    <div class="mt-1">Items de la facutra</div>
                </v-alert>
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr color="primary">
                                <th>ITEM</th>
                                <th>DESCRIPCIÓN</th>
                                <th>CANTIDAD</th>
                                <th>PRECIO</th>
                                <th>%IVA</th>
                                <th>IVA</th>
                                <th>TOTAL</th>
                                <th v-if="!noEdit">Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(si, x) in selectedInvoiceItems" :key=x>
                                <td style="width:300px">
                                    <v-autocomplete v-model=si.invoice_item_type_id placeholder="Item de la factura"
                                        :items=invoiceItems :rules=required item-value="invoice_item_type_id"
                                        item-text="description" :readonly="noEdit"></v-autocomplete>
                                </td>
                                <td style="width:400px">
                                    <v-text-field v-model="si.description" placeholder="Descripción" :rules=required
                                        style="font-size:13px" :readonly="noEdit"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field v-model="si.quantity" placeholder="Cantidad" type="number" min="0"
                                        style="font-size:13px" :rules="requiredMin" @keyup="calculateTotal"
                                        @change="calculateTotal" :readonly="noEdit"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field v-model="si.price" placeholder="precio" type="number" min="0"
                                        style="font-size:13px" @keyup="calculateTotal" @change="calculateTotal"
                                        :rules="requiredMin" :readonly="noEdit"></v-text-field>
                                </td>
                                <td>
                                    <v-autocomplete v-model=si.tax_authority_rate_seq_id placeholder="Iva" :items=taxtTypes
                                        :rules=required item-value="tax_authority_rate_seq_id" item-text="tax_percentage"
                                        @change="calculateTotal" :readonly="noEdit"></v-autocomplete>
                                </td>
                                <td>${{ si.tax }}</td>
                                <td>${{ si.total }}</td>
                                <td style="width:100px" v-if="!noEdit">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" color="primary" @click="storeInvoceItem(x)">
                                                mdi-floppy
                                            </v-icon>
                                        </template>
                                        <span>Guardar item</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" color="red" @click="deleteInvoiceItem(si)">
                                                mdi-delete-forever
                                            </v-icon>
                                        </template>
                                        <span>Eliminar item</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </tbody>
                    </template>                                                                                                                                                                                                                                                                                                                                                                                                                                                         
                </v-simple-table>
            </v-col>
            <v-row>
                <v-col cols=12 md="9">
                    <v-expansion-panels accordion v-model="panel">
                        <v-expansion-panel class='px-0 pb-0'>
                            <v-expansion-panel-header color="blue-grey lighten-5">
                                ASIENTOS CONTABLES
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <AccountingEntries :asientos=asientos />
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
                <v-col cols=12 md=3 class="pb-15">
                    <v-alert dense text icon="mdi-cash-100" light color="blue-grey darken-2">
                        Totales
                    </v-alert>
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td>Sub total</td>
                                    <td>${{ subTotal.toFixed(2) }}</td>
                                </tr>
                                <tr>
                                    <td>Sub total IVA 12%</td>
                                    <td>${{ iva12.toFixed(2) }}</td>
                                </tr>
                                <tr>
                                    <td>Sub total IVA 0%</td>
                                    <td>${{ iva0.toFixed(2) }}</td>
                                </tr>
                                <tr>
                                    <td>IVA</td>
                                    <td>${{ iva.toFixed(2) }}</td>
                                </tr>
                                <tr>
                                    <td>Total</td>
                                    <td><b>${{ total.toFixed(2) }}</b></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-row>
        <iframe name="framePrint" style="width:0px;height:0px;visibility:hidden"></iframe>
    </div>
</template>
<script>

import { mapState, mapMutations, mapActions } from 'vuex'
import AccountingEntries from '../general/AccountingEntries'
import Vue from 'vue'

export default {
    name: 'InvoiceForm',
    components: {
        AccountingEntries
    },
    props: {
        invoiceId: {
            type: String,
            default: null
        },
        suppliers: {
            type: Array,
            default: () => []
        }
    },
    data: () => ({
        supplier: null,
        subTotal: 0,
        iva0: 0,
        iva12: 0,
        iva: 0,
        total: 0,
        panel: '',
        noEdit: false,
        update: false,
        store: false,
        electroinicInvoice: true,
        pagos: [],
        invoice: {
            tipo_factura: null,
            codigo_establecimiento: null,
            codigo_punto_emision: null,
            invoice_number: null,
            invoice_id: null,
            inv_fecha: null,
            description: '',
            estado: '',
            autorizacion_sri: '',
            clave_acceso: '',
            forma_pago_id: null,
            invoice_id_from: ''
        },
        asientos: [],
        invoiceItems: [],
        invoicesProv: [],
        selectedInvoiceItems: [
            {
                invoice_id: '',
                invoice_item_type_id: '',
                secId: '',
                description: '',
                quantity: 1,
                price: 0,
                tax_authority_rate_seq_id: '',
                tax: 0,
                total: 0
            }
        ],
        taxtTypes: [],
        headItems: [
            { text: 'Opciones', value: 'opcion', align: "center", width: 50 },
            { text: 'No.', value: 'secId', align: "start" },
            { text: 'Descripcion', value: 'description', align: "start" },
            { text: 'Cantidad', value: 'cantidad', align: "end" },
            { text: 'Precio', value: 'valorUnitario', align: "end" },
            { text: '%IVA', value: 'pct_iva', align: "end" },
            { text: 'IVA', value: 'valor_iva', align: "end" },
            { text: 'Total', value: 'total', align: "end" },
        ],
        required: [
            v => !!v || 'El campo es requerido'
        ],
        requiredMin: [
            v => !!v || 'El campo es requerido',
            v => !!v && v > 0 || 'Mayor a cero',
        ],
        pagoTarjeta: false,
        menuPagos: [{ title: "Pago a proveedor" }, { title: "Cruzar con nota de crédito " }],
        
    }),
    computed: {
        ...mapState('master', ['paramAlertQuestion']),

        saldo() {
            let pagado = 0
            this.pagos.forEach((obj) => {
                pagado += parseFloat(obj.amount_applied.replace(',', ''))
            })

            return parseFloat(this.total - pagado).toFixed(2)
        }

        /*noEdit() {
            console.log(this.invoice.status_id , 'INVOICE_IN_PROCESS')
            return  this.invoice.invoice_type_id == 'PURC_RTN_INVOICE' || //NOTA CREDITO
                    this.invoice.invoice_type_id == 'PURCHASE_INVOICE' || //FACTURA DE COMPRA
                    this.invoice.status_id != 'INVOICE_IN_PROCESS' //EN PROCESO

        }*/
    },

    watch: {
        invoiceId: function (val) {
            this.getDataFactura()
        }
    },
    methods: {

        ...mapMutations('master', ['setUrl']),

        ...mapActions('master', ['requestApi', 'alertNotification']),

        getDataFactura() {

            this.setUrl(`invoice/${this.invoiceId}`)
            this.requestApi({
                method: 'GET',
                data: {}
            }).then(res => {

                const {
                    cabecera,
                    itemsFactura,
                    totales,
                    pagos,
                    total_pagado,
                    asientos
                } = res.data
                console.log(cabecera)
                this.supplier = cabecera.party_id_from

                this.pagos = pagos

                if (cabecera.due_date != null)
                    cabecera.due_date = cabecera.due_date.substring(0, 10)

                Object.assign(this.invoice, cabecera)
                this.asientos = asientos

                if (cabecera.forma_pago_id == "CREDIT_CARD") {
                    this.pagoTarjeta = true;
                } else {
                    this.pagoTarjeta = false;
                }
                //no editar es true
                //es nota de credito de compra de mercaderia
                //cualquier documento que no esté en IN_PROCESS
                this.noEdit = (this.invoice.parent_type_id == 'PURCHASE_INVOICE' && this.invoice.return_billing == true) ||
                    (this.invoice.parent_type_id == 'PURCHASE_INVOICE' && this.invoice.status_id != 'INVOICE_IN_PROCESS')

                // this.noEdit = (this.invoice.invoice_type_id == 'PURC_RTN_INVOICE' && this.invoice.return_billing == true) || //NOTA CREDITO
                //     (this.invoice.invoice_type_id == 'PURCHASE_INVOICE' && this.invoice.status_id != 'INVOICE_IN_PROCESS') || //FACTURA DE COMPRA
                //     (this.invoice.invoice_type_id == 'EXPENDING_INVOICE' && this.invoice.status_id != 'INVOICE_IN_PROCESS') || //FACTURA DE GASTO LISTA
                //     (this.invoice.invoice_type_id == 'LIQ_COMPRA' && this.invoice.status_id != 'INVOICE_IN_PROCESS') || //LIQ COMPRA
                //     (this.invoice.invoice_type_id == 'PURC_NOTA_VENTA' && this.invoice.status_id != 'INVOICE_IN_PROCESS') //LIQ COMPRA


                this.update = (this.invoice.status_id == 'INVOICE_IN_PROCESS') ||  //NO ES NC
                    (this.invoice.invoice_type_id == 'EXPENDING_INVOICE' && this.invoice.status_id == 'INVOICE_IN_PROCESS') ||//GASTO EN PRECESO
                    (this.invoice.invoice_type_id == 'LIQ_COMPRA' && this.invoice.status_id == 'INVOICE_IN_PROCESS') ||// LIQUIDACION DE COMPRA EN PROCESO
                    (this.invoice.invoice_type_id == 'NCC_INT_CMP' && this.invoice.status_id == 'INVOICE_IN_PROCESS') || // NOTA DE CREDITO INTERNA EN PROCESO
                    (this.invoice.invoice_type_id == 'NOTA_DEBITO_INT_CMP' && this.invoice.status_id == 'INVOICE_IN_PROCESS') || // NOTA DE DEBITO INTERNA EN PROCESO
                    (this.invoice.invoice_type_id == 'PURCHASE_INVOICE' && this.invoice.status_id == 'INVOICE_IN_PROCESS') || // FACTURA DE COMPRA EN PROCESO 
                    (this.invoice.invoice_type_id == 'PURC_RTN_INVOICE' && this.invoice.return_billing == false && this.invoice.status_id == 'INVOICE_IN_PROCESS')   //NOTA DE CREDITO SIN INVENTARIO

                this.invoicesProv = res.data.doc_proveedor;
                if (res.data.items.length) {

                    this.selectedInvoiceItems = []

                    let items = res.data.items.filter(e => { return e.invoiceItemTypeId != 'ITM_SALES_TAX' && e.invoiceItemTypeId != 'PITM_SALES_TAX' })
                    let taxes = res.data.items.filter(e => { return e.invoiceItemTypeId == 'ITM_SALES_TAX' || e.invoiceItemTypeId == 'PITM_SALES_TAX' })

                    items.forEach(obj => {
                        this.selectedInvoiceItems.push({
                            invoice_id: this.invoiceId,
                            invoice_item_type_id: obj.invoiceItemTypeId,
                            secId: obj.secId,
                            description: obj.description,
                            quantity: obj.cantidad.split(',').join(''),
                            price: obj.valorUnitario,
                            tax_authority_rate_seq_id: obj.impId,
                            tax: 0,
                            total: 0
                        })
                    })

                    taxes.forEach(obj => {
                        for (let index = 0; index < this.selectedInvoiceItems.length; index++) {
                            if (this.selectedInvoiceItems[index].secId == obj.parentInvoiceItemSeqId) {
                                this.selectedInvoiceItems[index].tax_authority_rate_seq_id = obj.impId
                                return
                            }
                        }
                    })
                }

                this.getInvoiceItems()
                this.$emit('invoiceName', `${this.invoice.tipo_factura}# ${this.invoice.codigo_establecimiento != null ? this.invoice.codigo_establecimiento + '-' : ''} ${this.invoice.codigo_punto_emision ? this.invoice.codigo_punto_emision + '-' : ''} ${this.invoice.invoice_number} / FA#${this.invoice.invoice_id}`)
                this.$emit('tipoDocumento', cabecera.invoice_type_id)

                if (cabecera.invoice_type_id == 'LIQ_COMPRA') {
                    this.$emit('claveAcceso', cabecera.clave_acceso)
                } else {
                    this.$emit('claveAcceso', cabecera.autorizacion_sri)

                }

            }).then(() => {

            }).catch(() => {

            })
        },

        getInvoiceItems() {

            this.setUrl(`invoice/0`)
            this.requestApi({
                method: 'PATCH',
                data: {
                    invoiceType: this.invoice.invoice_type_id
                }
            }).then(res => {
                this.invoiceItems = res.data.invoiceTypes
                this.taxtTypes = res.data.taxtTypes
                this.calculateTotal()
            })

        },

        addInvoiceItem() {
            this.selectedInvoiceItems.unshift({
                invoice_item_type_id: '',
                description: '',
                quantity: 1,
                price: 0,
                tax_authority_rate_seq_id: '',
                tax: 0,
                total: 0
            })
        },

        calculateTotal() {
        
            console.log(this.selectedInvoiceItems)
            this.subTotal = 0
            this.iva0 = 0
            this.iva12 = 0
            this.iva = 0
            this.total = 0
            this.selectedInvoiceItems.forEach((item, x, arr) => {
                let valTax = 0
                let objTax = this.taxtTypes.find(e => e.tax_authority_rate_seq_id == item.tax_authority_rate_seq_id)
                if (typeof objTax != 'undefined') valTax = objTax.tax_percentage
                let price = isNaN(parseFloat(item.price)) ? 0 : parseFloat(item.price)
                let quantity = isNaN(parseFloat(item.quantity)) ? 0 : parseFloat(item.quantity)
                let percentTaxt = item.tax_authority_rate_seq_id != '' ? (isNaN(parseFloat(valTax)) ? 0 : parseFloat(valTax)) : 0
                let subTotal = price * quantity
                if (valTax > 0)
                    this.iva12 += subTotal
                else
                    this.iva0 += subTotal
                this.subTotal += subTotal
                let tax = subTotal * (percentTaxt / 100)
                arr[x].tax = tax.toFixed(2)
                console.log(tax)
                this.iva += tax
                arr[x].total = (subTotal + tax).toFixed(2)

                this.total += subTotal + tax
            })
        },

        updateInvoice() {

            this.setUrl('invoice')
            this.requestApi({
                method: 'POST',
                data: {
                    typeAction: 'updateInvoice'
                }
            }).then(res => {
                console.log(res)
                this.emit('updateInvocie', true)

            })

        },

        storeInvoceItem(index) {

            this.setUrl('invoice')

            const {
                invoice_item_type_id,
                description,
                price,
                quantity,
                secId,
                tax,
                tax_authority_rate_seq_id,
                product_category_id,
                tax_percentage
            } = this.selectedInvoiceItems[index]

            this.requestApi({
                method: 'POST',
                data: {
                    typeAction: 'storeItemInvoice',
                    invoice_id: this.invoice.invoice_id,
                    invoice_item_type_id,
                    description,
                    price,
                    quantity,
                    secId,
                    tax,
                    tax_authority_rate_seq_id,
                    product_category_id,
                    tax_authority_rate_seq_id,
                    tax_percentage
                }
            }).then(res => {
                console.log(res)
                this.alertNotification({ param: { html: res.data.res.msg } })
                this.getDataFactura()

            })

        },

        deleteInvoiceItem(item) {

            Vue.swal({
                html: "Está seguro de eliminar el mitem de la factura?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then(result => {

                if (result.isConfirmed) {

                    if (typeof item.secId != 'undefined') {

                        this.setUrl('invoice')

                        const {
                            invoice_id,
                            secId
                        } = item

                        this.requestApi({
                            method: 'DELETE',
                            data: {
                                invoice_id,
                                secId,
                                typeDelete: 'deleteInvoiceItem',
                            }
                        }).then(res => {
                            this.alertNotification({ param: { html: res.data.detail.msg } })
                            this.getDataFactura()
                        })

                    }


                    let index = this.selectedInvoiceItems.indexOf(item)
                    this.selectedInvoiceItems.splice(index, 1)
                    this.calculateTotal()



                }
            })

        },

        storeHeaderInvoice() {

            const {
                codigo_establecimiento,
                codigo_punto_emision,
                inv_fecha,
                due_date,
                autorizacion_sri,
                invoice_id,
                invoice_number,
                invoice_type_id,
                forma_pago_id,
                invoice_id_from
            } = this.invoice

            if (this.electroinicInvoice && autorizacion_sri.length != 49) {
                Vue.swal({
                    html: "La clave de acceso está mal escrita, verifiquela",
                    showConfirmButton: false,
                    ...this.paramAlertQuestion,
                })
                return false
            }
            if (codigo_establecimiento == '' || codigo_establecimiento == null) {
                Vue.swal({
                    html: "Debe escribir el código del establecimiento",
                    showConfirmButton: false,
                    ...this.paramAlertQuestion,
                })
                return false
            }
            if (codigo_punto_emision == '' || codigo_punto_emision == null) {
                Vue.swal({
                    html: "Debe escribir el código el punto de emisión",
                    showConfirmButton: false,
                    ...this.paramAlertQuestion,
                })
                return false
            }
            if (inv_fecha == '' || inv_fecha == null) {
                Vue.swal({
                    html: "Debe escribir seleccionar la fecha de la factura",
                    showConfirmButton: false,
                    ...this.paramAlertQuestion,
                })
                return false
            }
            if (due_date == '' || due_date == null) {
                Vue.swal({
                    html: "Debe escribir seleccionar la fecha de vencimiento la factura",
                    showConfirmButton: false,
                    ...this.paramAlertQuestion,
                })
                return false
            }
            if (invoice_number == '' || invoice_number == null) {
                Vue.swal({
                    html: "Debe escribir el número de la factura",
                    showConfirmButton: false,
                    ...this.paramAlertQuestion,
                })
                return false
            }
            if (autorizacion_sri == '' || autorizacion_sri == null) {
                Vue.swal({
                    html: "Debe escribir la clave de acceso de la factura",
                    showConfirmButton: false,
                    ...this.paramAlertQuestion,
                })
                return false
            }

            let secuencial = invoice_number

            Vue.swal({
                html: "Al guadar la cabecera de la factura ya no se podrá editar, está seguro?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then(result => {

                if (result.isConfirmed) {
                    this.store = true
                    this.setUrl('invoice')

                    this.requestApi({
                        method: 'POST',
                        data: {
                            typeAction: 'updateHeaderPurchaseInvoice',
                            estab: codigo_establecimiento,
                            pto_emi: codigo_punto_emision,
                            inv_fecha: inv_fecha.substring(0, 10),
                            due_date: due_date.substring(0, 10),
                            secuencial,
                            clave_acceso: autorizacion_sri,
                            invoice_id,
                            invoice_type_id,
                            invoice_id_from,
                            forma_pago_id: this.pagoTarjeta == true ? 'CREDIT_CARD' : null,
                            electroinicInvoice: this.electroinicInvoice && autorizacion_sri.length == 49
                        }
                    }).then(res => {
                        //console.log(res)
                        this.alertNotification({ param: { html: res.data.res.msg } })
                        //this.invoiceItems = res.data.invoiceTypes
                        //this.taxtTypes = res.data.taxtTypes

                        // this.calculateTotal()
                        this.$emit('storeInvoice', true)

                    }).then(() => {
                        this.store = false
                    }).catch(() => {
                        this.store = false
                    })

                }

            })

        },

        downloadPdfRetention(retention) {
            console.log(retention)
            window.frames['framePrint'].location.replace('https://sufactura.ec/descargar-ride?claveAcceso=' + retention.clave_acceso)

        }

    },
    mounted() {
        this.getDataFactura()

    }
}
</script>